import React, { Component } from "react";
import Header from "../layout/Header";

export default class ChairmanVSDS extends Component {
  render() {
    return (
      <div className="site-main">
        <Header />
        {/* page-title */}
        <div className="ttm-page-title-row principalbanner">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading card_box">
                    <h4 className="title" style={{ color: "white" }}>
                      Chairman
                    </h4>
                    <div className="ttm-color-home">
                      <span className="ttm-color-home">
                        <a
                          title="Homepage"
                          href={process.env.PUBLIC_URL + "/"}
                          className="ttm-color-home"
                        >
                          <i className="ti ti-home" />
                          &nbsp;&nbsp;Home
                        </a>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span className="ttm-color-home">VSDCH</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="ttm-row aboutus-section clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="section-title with-desc clearfix"
                  style={{ paddingBlockEnd: "40px" }}
                >
                  <div className="title-header">
                    <h2 className="title">
                      Chairman<span> Message</span>
                    </h2>
                  </div>
                  <div className="title-desc-ff">
                    <p className="text-justify">
                      It is my honor to welcome you to V. S. Dental College and
                      Hospital, a unique dental Institute committed to develop
                      health professionals who will maintain and improve the
                      oral health status of individuals and populations. We
                      focus on developing dental education suitable to produce
                      future empowered practitioners.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="position-relative res-991-mt-30">
                  <div className="ttm_single_image-wrapper text-sm-center">
                    <div className="featured-imagebox featured-imagebox-portfolio ">
                      <div className="featured-thumbnail">
                        <img
                          className="img-fluid"
                          src="./images/Chairman.jpg"
                          title="single-img-seven"
                          alt="single-img-seven"
                          style={{ height: "400px" }}
                        />
                      </div>
                      <div>
                        <div>
                          <div
                            className="member-info-box border mt-2"
                            style={{ padding: "10px", textAlign: "left" }}
                          >
                            <div className="timing-box">
                              <h4>Sri . j . Raju </h4>
                            </div>
                            <ul className="info-list">
                              <li>Chairman Governing Council</li>
                              <li>V.S.D.C.H</li>
                              <li>Director RVS</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* row end */}
          </div>
        </section>
        {/* Principal-section end*/}
      </div>
    );
  }
}
